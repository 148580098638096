import React, { Component } from 'react'
import { UnitResourceProps } from '../UnitResourceAdmin'
// @ts-ignore
import { UpdateEntity } from '@humanics/he-react-common/lib/admin/components'
import operationalDetailItems from '../../common/operationalDetailItems.json'

interface FormType {
  [key: string]: any
}

interface UpdateUnitResourceProps extends UnitResourceProps {
  updateOperationId: string
}

const inputDefinitionExtraFields = {
  name: {
    label: 'Name',
    type: 'string',
    required: true,
    formItemClasses: 'offset-1 col-7 flex',
    formLabelClasses: 'flex col-5 align-right pr20'
  },
  unitId: {
    label: 'Unit',
    inputType: 'InputDropdownUnits',
    model: 'unit',
    withLabel: true,
    required: true,
    formItemClasses: 'offset-1 col-7 flex',
    formLabelClasses: 'flex col-5 align-right pr20'
  },
  defaultDetails: {
    label: 'Default Operational Setting',
    inputType: 'InputOperationalDetail',
    model: 'unitResourceDefaultDetails',
    formItemClasses: 'offset-3',
    items: operationalDetailItems
  },
  operationalDetails: {
    label: 'Custom Operational Setting',
    inputType: 'InputOperationalDetails',
    default: [],
    dependsOnFormData: true,
    items: {
      isWorking: {
        type: 'boolean',
        default: false
      },
      operationalDetail: {
        label: 'operationalDetail',
        inputType: 'InputOperationalDetail',
        items: operationalDetailItems
      }
    }
  }
}

export default class UpdateUnitResource extends Component<UpdateUnitResourceProps> {
  onSubmit = (e: React.FormEvent, form: FormType) => {
    const { type, store, history, match,updateOperationId } = this.props
    const { id } = match.params
    const updatedForm = {
      ...form,
      unitResourceType: type
    }
    return store.update({ updateOperationId, form: updatedForm }, id, { unitResourceTypes: [type] }).then(() => history.push('.'))
  }

  render() {
    const updateProps = {
      ...this.props,
      inputDefinitionExtraFields
    }
    return <UpdateEntity {...updateProps} onSubmit={this.onSubmit} width={900} />
  }
}
