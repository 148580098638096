import { Component } from 'react'
import { CreateEntity } from '@humanics/he-react-common/lib/admin/components'
import { cloneDeep, pick } from 'lodash'
import { isFacilityEnabledFeature } from 'utils'
import { t } from 'i18n'
import { getDefaultDetailsLengthForShift } from 'utils/getDefaultDetailsLengthForShift'
import operationalDetailItems from '../../common/operationalDetailItems.json'
const expertiseFields = ['licenseIds', 'certificationIds', 'skillIds', 'equipmentSkillIds']

export default class CreateShift extends Component {
  constructor(props) {
    super(props)
    this.state = {
      supportsRemoteEquipments: false,
      remoteEquipments: [],
      isDataFetched: false,
      alreadyLinkedEquipmentIds: new Set()
    }
    this.inputDefinitionExtraFields = {
      unitId: {
        label: 'Unit',
        inputType: 'InputDropdownUnits',
        model: 'unit'
      },
      typeId: {
        label: 'Shift type',
        inputType: 'InputDropdownShiftTypes',
        default: [],
        withLabel: true
      },
      licenseIds: {
        label: 'License',
        loadItemsParameters: {
          type: 'license'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        default: [],
        items: {
          expertiseId: {
            label: 'License',
            inputType: 'ExpertiseDropdown',
            withLabel: true
          }
        }
      },
      certificationIds: {
        label: 'Credentials/Certifications',
        loadItemsParameters: {
          type: 'certification'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        default: [],
        items: {
          expertiseId: {
            label: 'Credentials/Certifications',
            inputType: 'ExpertiseDropdown',
            withLabel: true
          }
        }
      },
      skillIds: {
        label: 'Skill',
        loadItemsParameters: {
          type: 'skill'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        default: [],
        items: {
          expertiseId: {
            label: 'Select Skill',
            inputType: 'ExpertiseDropdown',
            withLabel: true
          },
          eligibleScaleIndexes: {
            label: 'Minimum Competence',
            inputType: 'ScaleItemsDropdown',
            withLabel: true,
            dependsOn: 'expertiseId'
          }
        }
      },
      equipmentSkillIds: {
        label: 'Equipment Skill',
        loadItemsParameters: {
          type: 'equipment'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        default: [],
        items: {
          expertiseId: {
            label: 'Equipment + Skill',
            inputType: 'GroupedExpertiseDropdown',
            withLabel: true
          },
          eligibleScaleIndexes: {
            label: 'Minimum Competence',
            inputType: 'ScaleItemsDropdown',
            withLabel: true,
            dependsOn: 'expertiseId'
          }
        }
      },
      isEnabledRemoteService: {
        label: 'Enable Remote Services',
        type: 'boolean',
        isVisible: () => this.props.type === 'equipment',
        onChange: (e) => {
          if (e.target.checked) {
            this.loadRemoteEquipments()
          }
        }
      },
      deviceId: {
        label: 'Remote Equipment',
        inputType: 'InputDropdown',
        withLabel: true,
        required: true,
        enum: [],
        propKey: 'deviceId',
        propValue: 'name',
        isVisible: (formState) => formState.isEnabledRemoteService,
        isDisabledOption: (data) => this.state.alreadyLinkedEquipmentIds.has(data.deviceId),
        disabledOptionReason: t('equipments.already_linked')
      },
      name: {
        withLabel: true,
        required: true,
        type: 'string',
        isVisible: (formState) => !formState.isEnabledRemoteService
      },
      resourceType: {
        value: this.props.type
      },
      defaultDetails: {
        label: 'Default Operational Setting',
        inputType: 'InputOperationalDetail',
        model: 'unitResourceDefaultDetails',
        formItemClasses: 'offset-3',
        items: operationalDetailItems
      },
      operationalDetails: {
        label: 'Custom Operational Setting',
        inputType: 'InputOperationalDetails',
        default: {},
        dependsOnFormData: true,
        items: {
          isWorking: {
            type: 'boolean',
            default: false
          },
          operationalDetail: {
            label: 'operationalDetail',
            inputType: 'InputOperationalDetail',
            items: operationalDetailItems
          }
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.type !== 'equipment') {
      return
    }

    const doesFacilitySupportRemoteEquipments = isFacilityEnabledFeature(
      this.props.appState,
      'remote_workflow_wescan',
      ['write']
    )

    const message = doesFacilitySupportRemoteEquipments
      ? t('equipments.facility_supports_remote_services')
      : t('equipments.facility_does_not_support_remote_services')

    this.setState({ supportsRemoteEquipments: doesFacilitySupportRemoteEquipments, message })
  }

  async loadRemoteEquipments() {
    if (!this.state.supportsRemoteEquipments || this.state.isDataFetched) {
      return
    }

    const store = this.props.stores.get('shift')
    const remoteEquipmentsList = await store.loadRemoteEquipments()
    const weScanRemoteEquipments = remoteEquipmentsList.map((equipment) => equipment.data.wescan)

    await this.updateAlreadyLinkedEquipmentIds(store)

    this.setState({ remoteEquipments: weScanRemoteEquipments, isFetchingData: false, isDataFetched: true })
  }

  async updateAlreadyLinkedEquipmentIds(store) {
    const equipments = await store.loadShiftsWithResourceType([this.props.type])
    const equipmentIdsSet = new Set()

    for (const equipment of equipments) {
      if (equipment.remoteDetails?.isEnabledRemoteService) {
        equipmentIdsSet.add(equipment.remoteDetails.deviceId)
      }
    }

    this.setState({ alreadyLinkedEquipmentIds: equipmentIdsSet })
  }

  render() {
    this.inputDefinitionExtraFields.isEnabledRemoteService.disabled = !this.state.supportsRemoteEquipments
    this.inputDefinitionExtraFields.isEnabledRemoteService.expectedFormat = (
      <sh-icon
        icon="information"
        color="#808fa4"
        size="xs"
        title={this.state.message}
        style={{ display: 'inline-block', top: '-2px' }}
      ></sh-icon>
    )

    if (this.state.supportsRemoteEquipments) {
      this.inputDefinitionExtraFields.deviceId.enum = this.state.remoteEquipments
    }

    const createProps = {
      ...this.props,
      inputDefinitionExtraFields: this.inputDefinitionExtraFields
    }

    return <CreateEntity {...createProps} width={900} onSubmit={this.onSubmit} />
  }

  prepareExpertise = (form) => {
    let expertiseRequirements = []
    expertiseFields.forEach((field) => {
      const data = form[field] || []
      const availableFields = Object.keys(this.inputDefinitionExtraFields[field].items)
      const filteredData = data.map((item) => pick(item, availableFields))
      expertiseRequirements = [...expertiseRequirements, ...filteredData]
    })
    return expertiseRequirements
  }

  getDefaultValuesForTargetCover() {
    return { min: 0, max: 0 }
  }

  prepareOperationalDetails = (operationalDetails) => {
    const updatedOperationalDetails = cloneDeep(operationalDetails)
    for (const day in updatedOperationalDetails) {
      // sanitize non-working operational days
      if (!updatedOperationalDetails[day].isWorking) {
        updatedOperationalDetails[day].operationalDetail = undefined
      } else {
        if (updatedOperationalDetails[day].operationalDetail) {
          const targetCoverForDay = updatedOperationalDetails[day].operationalDetail.targetCover
          if (!targetCoverForDay)
            updatedOperationalDetails[day].operationalDetail.targetCover = this.getDefaultValuesForTargetCover()
        }
      }
    }
    return updatedOperationalDetails
  }

  onSubmit = (e, form) => {
    const { store, history, createOperationId, type } = this.props
    const expertiseRequirements = this.prepareExpertise(form)
    const operationalDetails = this.prepareOperationalDetails(form.operationalDetails)

    let { startTime, targetCover, allowableGap } = form.defaultDetails

    if (!targetCover) {
      targetCover = this.getDefaultValuesForTargetCover()
    }

    const defaultDetailsLength = getDefaultDetailsLengthForShift(form.defaultDetails)

    const shift = {
      ...form,
      expertiseRequirements,
      startTime,
      length: defaultDetailsLength,
      targetCover,
      allowableGap,
      operationalDetails
    }

    if (shift.isEnabledRemoteService) {
      shift.name = 'Remote linked shift'
      shift.remoteDetails = {
        isEnabledRemoteService: shift.isEnabledRemoteService,
        deviceId: shift.deviceId
      }
    }

    delete shift.isEnabledRemoteService
    delete shift.deviceId
    delete shift.defaultDetails

    return store
      .create({ createOperationId, form: shift }, null, { resourceTypes: [type] })
      .then(() => history.push('.'))
  }
}
