import { PureComponent } from 'react'
import StickyList from './StickyList'
import { List } from 'immutable'
import DayStaff from './VStaff/DayStaff'
import VRole from './VRole/VRole'
import DayShift from './VShift/DayShift'
import Footer from 'Footer'
import { CalendarFilterService, ShiftService } from 'services'

const FOOTER_HEIGHT = 39
const STAFF_HEIGHT = 49
const SHIFT_HEIGHT = 28
const STAFF_DIVIDER_HEIGHT = 2
const GROUP_HEIGHT = 31

export default class DayGrid extends PureComponent {
  static defaultProps = { overscanRowCount: 0 }

  constructor(props, context) {
    super(props, context)
    const raw = this.props.calendar.get('raw')
    const listSize = raw ? raw.size : 0
    const { overscanRowCount } = props

    this.state = {
      listHeight: 600,
      listRowHeight: 50,
      overscanRowCount,
      rowCount: listSize,
      scrollToIndex: undefined,
      showScrollingPlaceholder: false,
      useDynamicRowHeight: false,
      dayIndex: -1,
      viewPreference: '',
      staffMistmatch: null
    }
  }

  componentDidMount() {
    const {
      usDate,
      resetLoadConfig,
      loadCalendarDayView,
      loadCalendar,
      unit,
      loadOtherStaffWithEvents,
      isOtherStaffLoaded,
      activeDateRange,
      resetOtherStaffFilter
    } = this.props
    const isDateRangeReady = activeDateRange.get('isReady') === true
    resetOtherStaffFilter()
    if (unit.get('urlId')) {
      if (!isOtherStaffLoaded) {
        loadOtherStaffWithEvents(usDate)
      }
      if (isDateRangeReady) {
        loadCalendar()
      }
      loadCalendarDayView(usDate)
    }

    if (isDateRangeReady) {
      loadCalendar()
    }
    resetLoadConfig()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      activeDateRange,
      loadCalendarDayView,
      calendar,
      resetLoadConfig,
      unit,
      usDate,
      timeService,
      loadCalendar,
      getUnitManagerViewPreferences,
      unitId
    } = this.props

    const calendarDateRange = calendar.get('dateRange')
    const isDateRangeChanged = calendarDateRange !== activeDateRange
    const isDateRangeReady = activeDateRange.get('isReady') === true
    const dateTime = timeService.timeMoment(usDate).toISOString()
    const dayIndex = activeDateRange.get('days', []).findIndex((day) => day.usDate === usDate)

    const isDayIndexChanged = prevState.dayIndex !== dayIndex

    const viewPreferences = getUnitManagerViewPreferences()
    const viewPreference = viewPreferences.units[unitId]?.viewPreference || ''

    const isViewPrefChanged = viewPreference !== prevState.viewPreference

    if (isDayIndexChanged) {
      this.setState({ dayIndex })
    }
    if (isViewPrefChanged) {
      this.setState({ viewPreference: viewPreference })
    }

    if ((isDateRangeChanged && isDateRangeReady && unit.get('urlId')) || isViewPrefChanged) {
      loadCalendar()
      loadCalendarDayView(dateTime)
      resetLoadConfig()
    }
  }

  render() {
    const { mode } = this.props
    const { dayIndex } = this.state
    if (dayIndex === -1) {
      return null
    }
    const raw = this.props.getViewModel() || List()
    const listSize = raw ? raw.size : 0
    const { overscanRowCount, scrollToIndex } = this.state
    const props = {
      raw,
      filters: this.props.filters,
      headerHeight: GROUP_HEIGHT + SHIFT_HEIGHT,
      onScroll: this.handleScroll,
      defaultHeight: 500,
      containerStyle: { maxHeight: 'auto' },
      height: this.props.offsetHeight,
      overscanRowCount: overscanRowCount,
      // noRowsRenderer: this.noRowsRenderer,
      rowCount: listSize,
      rowHeight: this.getRowHeightByIndex(raw),
      rowRenderer: this.rowRenderer(raw),
      scrollToIndex: scrollToIndex,
      getRowHeight: this.getRowHeight,
      renderShift: this.renderShift,
      renderRole: this.renderRole,
      getRole: this.props.getRole,
      getShift: this.props.getShift,
      viewMode: mode,
      isStaffView: this.props.isStaffView
    }

    return <StickyList {...props} />
  }

  handleScroll = ({ scrollTop }) => this.props.updateScrollTop(scrollTop)

  updateShift = (isRole, datum, isShift, isStaff) => {
    const { filters, isStaffView } = this.props
    const showMergedShift = filters.get('showMergedShift') && isStaffView
    if (showMergedShift && isRole) {
      const shifts = datum.get('shifts')
      const calendarFilterService = new CalendarFilterService(filters)
      shifts.forEach((shift) => {
        if (calendarFilterService.isShiftVisible(shift, true)) {
          this.props.loadShift(shift.get('id'))
        }
      })
    } else if (!showMergedShift && (isShift || isStaff)) {
      const shiftId = datum.get(isShift ? 'id' : 'shiftId')
      this.props.loadShift(shiftId)
    }
  }

  rowRenderer =
    (raw) =>
    ({ index, isScrolling, key, style }) => {
      const datum = this.getDatum(raw, index)
      const kind = datum.get('kind')

      const isStaff = kind === 'staff'
      const isStaffDivider = kind === 'staff-divider'
      const isShift = kind === 'shift'
      const isRole = kind === 'role'
      const isFooter = kind === 'footer'

      const roleId = datum.get('id')
      if (!isScrolling) {
        //do not load cells while scrolling.
        this.updateShift(isRole, datum, isShift, isStaff)
      }
      const isOtherStaff = roleId === 'secondary-staff'
      if (isOtherStaff) {
        const { calendar, loadOtherStaffWithEvents, filters, usDate } = this.props
        const isOtherStaffLoaded = calendar.get('isOtherStaffLoaded')
        const isCollapsed = filters.getIn(['collapsed', roleId]) === true

        if (!isOtherStaffLoaded && !isCollapsed) {
          loadOtherStaffWithEvents(usDate)
        }
      }
      if (isShift) {
        return this.renderShift(datum, style)
      }
      if (isStaff) {
        return this.renderStaff(datum, style)
      }
      if (isStaffDivider) {
        return this.renderStaffDivider(datum, style)
      }
      if (isRole) {
        return this.renderRole(datum, style)
      }
      if (isFooter) {
        return this.renderFooter(style)
      }
    }

  renderFooter(style) {
    const { top, ...rest } = style
    return <Footer key="footer" style={{ ...rest, bottom: 0 }} />
  }

  renderStaff(staff, style) {
    const {
      filters,
      activeDateRange,
      showPopup,
      selection,
      onMouseMoveOverGrid,
      cellsPathsInProcessing,
      selectSingleCell,
      updateEvent,
      updateCellTooltip,
      unitUrlId,
      displayNameAs,
      getShift,
      unitShiftsMap,
      facilityShiftsMap,
      calendar,
      staffManagersMap,
      timeService
    } = this.props
    const { dayIndex } = this.state
    const notes = calendar.get('notes') || []
    const roleIndex = staff.get('roleIndex')
    const shiftIndex = staff.get('shiftIndex')
    const staffIndex = staff.get('staffIndex')
    const shift = staff && getShift({ roleIndex, shiftIndex })
    const shiftId = shift.get('id')
    const scheduleState = activeDateRange.get('scheduleState')
    const staffPath = { roleIndex, shiftIndex, staffIndex }
    const staffMismatch = this.state[shiftId]
    const onCellEvent = (cellIndex, eventType, meta) => this.props.onCellEvent(staffPath, cellIndex, eventType, meta)

    const onMouseMoveOverGridCell = (event) => {
      const cellIndex = parseInt(event.target.getAttribute('data-cell-index'))
      return onMouseMoveOverGrid(event, roleIndex, shiftIndex, staffIndex, staff, cellIndex)
    }

    const selectedStaffPath = selection.get('staffPath')
    const {
      staffIndex: selectedStaffIndex,
      shiftIndex: selectedShiftIndex,
      roleIndex: selectedRoleIndex
    } = selectedStaffPath

    const isStaffSelected = selectedStaffIndex === staffIndex
    const isShiftSelected = selectedShiftIndex === shiftIndex
    const isRoleSelected = selectedRoleIndex === roleIndex

    const isRowSelected = isRoleSelected && isShiftSelected && isStaffSelected
    const actualSelection = isRowSelected && selection

    const staffId = staff.get('id')
    const indicators = filters.get('indicators')
    const dayViewFilters = filters.get('dayViewFilters')
    const dayStaffProps = {
      key: staffId,
      shiftIndex: staffMismatch,
      staff,
      style,
      showPopup,
      indicators,
      dayViewFilters,
      selection: actualSelection,
      scheduleState,
      activeDateRange,
      onCellEvent,
      onMouseMoveOverGridCell,
      cellsPathsInProcessing,
      updateCellTooltip,
      unitUrlId,
      displayNameAs,
      shift,
      dayIndex,
      timeService,
      unitShiftsMap,
      facilityShiftsMap,
      notes,
      staffManagersMap,
      selectSingleCell,
      updateEvent,
      staffMismatch
    }
    return <DayStaff {...dayStaffProps} />
  }

  renderStaffDivider(datum, style) {
    return <hr className="staff-divider" key={datum.get('key')} style={style} />
  }

  renderShift = (shift, style) => {
    const { activeDateRange, hideOpenShiftPopup, filters, timeService, openShiftPopupProps, isStaffView } = this.props

    const showMergedShift = filters.get('showMergedShift') && isStaffView
    const roleIndex = shift.get('roleIndex')
    const shiftIndex = shift.get('shiftIndex')
    const shiftId = shift.get('id')
    const isOtherStaff = shift.get('isOtherStaff')

    const shiftService = new ShiftService(shift, timeService)
    const shiftRange = showMergedShift || isOtherStaff ? '' : shiftService.getShiftTime()

    const showOpenShiftPopup = (event, dayIndex) =>
      this.props.showOpenShiftPopup(event, roleIndex, shiftIndex, dayIndex)
    const staffMismatch = shift.get('shiftMismatch')
    this.setState({ [shiftId]: staffMismatch })
    return (
      <DayShift
        key={shiftId}
        shift={shift}
        style={style}
        activeDateRange={activeDateRange}
        showOpenShiftPopup={showOpenShiftPopup}
        hideOpenShiftPopup={hideOpenShiftPopup}
        filters={filters}
        shiftRange={shiftRange}
        openShiftPopupProps={openShiftPopupProps}
        timeService={timeService}
        isStaffView={isStaffView}
      />
    )
  }

  renderRole = (role, style) => {
    const { filters, activeDateRange, onChangeViewPreference, unitId, mode } = this.props
    const roleId = role.get('id')

    return (
      <VRole
        key={roleId}
        role={role}
        style={style}
        activeDateRange={activeDateRange}
        unitId={unitId}
        filters={filters}
        toggleIsCollapsed={() => this.props.toggleIsCollapsed(role)}
        onChangeViewPreference={onChangeViewPreference}
        mode={mode}
        calendarType="calendar"
      />
    )
  }

  getRowHeightByIndex =
    (raw) =>
    ({ index }) => {
      const row = raw.get(index)
      return this.getRowHeight(row)
    }

  getRowHeight = (row) => {
    if (!row) {
      return 0
    }
    if (row.get('hidden')) {
      return 0
    }

    switch (row.get('kind')) {
      case 'footer':
        return FOOTER_HEIGHT
      case 'staff':
        return STAFF_HEIGHT
      case 'shift':
        return SHIFT_HEIGHT
      case 'staff-divider':
        return STAFF_DIVIDER_HEIGHT
      case 'role':
        return GROUP_HEIGHT
      default:
        return 0
    }
  }

  getDatum = (raw, index) => {
    return raw.get(index % raw.size)
  }
}
