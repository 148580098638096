import { fromJS, List, Map } from 'immutable'
import StaffEligibilityService from './StaffEligibilityService'

export default class FacilityUserShiftsService {
  static userIdsByShiftId(startDate, endDate, unitId, users, shifts, timeService) {
    const usersByShiftId = FacilityUserShiftsService.usersByShiftId(
      startDate,
      endDate,
      unitId,
      fromJS(users),
      fromJS(shifts),
      timeService
    )
    return usersByShiftId.map((users, shiftId) => users.map((user) => user.get('userId')))
  }

  static usersByShiftId(startDate, endDate, unitId, users, shifts, timeService) {
    let usersByShiftId = Map()

    const secondaryStaff = users.filter((user) => {
      const eligibleUnits = user.getIn(['staffProfile', 'eligibleUnits'])
      const staffEligibilityService = new StaffEligibilityService(timeService)
      const eligibleUnitsForDateRange = staffEligibilityService.getEligibleUnitsForDateRange(
        startDate,
        endDate,
        eligibleUnits
      )

      // Display staff only once in current primary shift:
      const userShiftsService = new FacilityUserShiftsService(timeService)
      const closestPrimaryShiftId = userShiftsService.getClosestPrimaryShiftId(
        unitId,
        eligibleUnitsForDateRange,
        shifts
      )

      if (closestPrimaryShiftId) {
        usersByShiftId = usersByShiftId.updateIn([closestPrimaryShiftId], (users) => {
          const userId = user.get('userId')
          const isAlreadyInList = users?.find((user) => user.get('userId') === userId)
          if (isAlreadyInList) {
            return users
          }
          if (users) {
            return users.push(user)
          }
          return List([user])
        })
      }

      return !closestPrimaryShiftId
    })

    usersByShiftId = usersByShiftId.set('secondary-staff-shift', secondaryStaff)

    return usersByShiftId
  }

  static shiftIdsByUserId(userIdsByShiftId, startDate, endDate, unitId, users, shifts, timeService) {
    if (!userIdsByShiftId) {
      userIdsByShiftId = userIdsByShiftId(startDate, endDate, unitId, users, shifts, timeService)
    }
    const shiftIdsByUserId = {}
    userIdsByShiftId.forEach((userIds, shiftId) => {
      userIds.forEach((userId) => {
        if (!shiftIdsByUserId[userId]) {
          shiftIdsByUserId[userId] = []
        }
        shiftIdsByUserId[userId].push(shiftId)
      })
    })
    return fromJS(shiftIdsByUserId)
  }

  static getOtherStaffForUnit(
    unitId,
    startDate,
    endDate,
    facilityUsers,
    shifts,
    timeService,
    staffCoordinatorEligibleUnits
  ) {
    const staffEligibilityService = new StaffEligibilityService(timeService)
    const staffCoordinatorEligibleUnitIds = staffCoordinatorEligibleUnits?.map((unit) => unit.get('id')).toSet()

    return facilityUsers.filter((user) => {
      const eligibleUnits = user.getIn(['staffProfile', 'eligibleUnits'])
      const eligibleUnitsForDateRange = staffEligibilityService.getEligibleUnitsForDateRange(
        startDate,
        endDate,
        eligibleUnits
      )

      if (eligibleUnitsForDateRange.size === 0) {
        return false
      }

      const staffEligibleUnitId = staffEligibilityService?.getCurrentEligibleUnit(eligibleUnits)?.get('homeUnitId')

      if (!staffCoordinatorEligibleUnitIds?.has(staffEligibleUnitId)) {
        return false
      }

      const userShiftsService = new FacilityUserShiftsService(timeService)
      const closestPrimaryShiftId = userShiftsService.getClosestPrimaryShiftId(
        unitId,
        eligibleUnitsForDateRange,
        shifts
      )

      return !closestPrimaryShiftId
    })
  }

  constructor(timeService) {
    this.timeService = timeService
    this.staffEligibilityService = new StaffEligibilityService(timeService)
  }

  getClosestPrimaryShiftId(unitId, eligibleUnits, shifts) {
    const eligibleUnit = this.staffEligibilityService.getClosestEligibleUnit(unitId, eligibleUnits)

    if (!eligibleUnit) {
      return null
    }

    const homeUnitId = eligibleUnit.get('homeUnitId')
    const homeUnitRoleId = eligibleUnit.get('homeUnitRoleId')
    const homeUnitShiftTypeId = eligibleUnit.get('homeUnitShiftTypeId')

    const shift = shifts.find((shift) => {
      const shiftUnitId = shift.get('unitId')
      const shiftTypeId = shift.get('typeId')
      const shiftRoleId = shift.get('unitRoleId')

      const isSameUnit = shiftUnitId === homeUnitId
      const isSameRole = homeUnitRoleId === shiftRoleId
      const isSameShiftType = shiftTypeId === homeUnitShiftTypeId

      return isSameUnit && isSameShiftType && isSameRole
    })

    return shift ? shift.get('id') : null
  }

  getStaffShiftsByUnitIds(dates, eligibleUnits, shifts, availableUnitIds) {
    const unitIds = this.staffEligibilityService.getEligibleUnitIdsForDates(eligibleUnits, dates, availableUnitIds)
    const unitsShifts = shifts.filter((shift) => unitIds.includes(shift.get('unitId')))
    return unitsShifts.groupBy((shift) => shift.get('unitId'))
  }

  getPrimaryShiftId(eligibleUnits, date, shifts) {
    const primaryShift = this.getPrimaryShift(eligibleUnits, date, shifts)
    return primaryShift?.get('id')
  }

  getPrimaryShift(eligibleUnits, date, shifts) {
    const eligibleUnit = this.staffEligibilityService.getEligibleUnitForDate(eligibleUnits, date)
    return this._getMatchingShiftForUnit(eligibleUnit, shifts)
  }

  _getSecondaryShifts(eligibleUnits, date, shifts, primaryShiftId, availableUnitIds) {
    const eligibleUnit = this.staffEligibilityService.getEligibleUnitForDate(eligibleUnits, date)

    if (!eligibleUnit) {
      return List()
    }

    return shifts.filter((shift) => {
      const shiftId = shift.get('id')
      const unitId = shift.get('unitId')
      const isUnitAvailable = availableUnitIds.includes(unitId)
      const isSecondaryShift = primaryShiftId !== shiftId && !shift.get('isOnCall')

      return isSecondaryShift && isUnitAvailable
    })
  }

  _getOnCallShifts(eligibleUnits, date, shifts, availableUnitIds) {
    const eligibleUnit = this.staffEligibilityService.getEligibleUnitForDate(eligibleUnits, date)

    if (!eligibleUnit) {
      return List()
    }

    return shifts.filter((shift) => {
      const unitId = shift.get('unitId')
      const isUnitAvailable = availableUnitIds.includes(unitId)
      const isOnCallShift = shift.get('isOnCall')

      return isOnCallShift && isUnitAvailable
    })
  }

  getShiftsForDate(dateTime, staff, shifts, availableUnitIds) {
    const eligibleUnits = staff.getIn(['staffProfile', 'eligibleUnits'])
    const primaryShift = this.getPrimaryShift(eligibleUnits, dateTime, shifts)
    const primaryShiftId = primaryShift?.get('id')
    const secondaryShifts = this._getSecondaryShifts(eligibleUnits, dateTime, shifts, primaryShiftId, availableUnitIds)
    const onCallShifts = this._getOnCallShifts(eligibleUnits, dateTime, shifts, availableUnitIds)

    return { secondaryShifts, primaryShift, onCallShifts }
  }

  _getCurrentPrimaryShift(eligibleUnits, shifts) {
    const eligibleUnit = this.staffEligibilityService.getCurrentEligibleUnit(eligibleUnits)
    return this._getMatchingShiftForUnit(eligibleUnit, shifts)
  }

  _getMatchingShiftForUnit(eligibleUnit, shifts) {
    if (!eligibleUnit) {
      return null
    }

    const homeUnitId = eligibleUnit.get('homeUnitId')
    const homeUnitRoleId = eligibleUnit.get('homeUnitRoleId')
    const homeUnitShiftTypeId = eligibleUnit.get('homeUnitShiftTypeId')

    return shifts.find((shift) => {
      const shiftUnitId = shift.get('unitId')
      const shiftTypeId = shift.get('typeId')
      const shiftRoleId = shift.get('unitRoleId')

      const isSameUnit = shiftUnitId === homeUnitId
      const isSameRole = homeUnitRoleId === shiftRoleId
      const isSameShiftType = shiftTypeId === homeUnitShiftTypeId

      return isSameUnit && isSameShiftType && isSameRole
    })
  }
}
