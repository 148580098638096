import { t } from 'i18n'
import { navigate } from 'Navigation'
import { createRef, PureComponent } from 'react'
import logo from '../../styles/web_v3/logo-b.png'

export class PrivacyPolicy extends PureComponent {
  onClose(e) {
    e.preventDefault()
    navigate.clearHash()
  }

  constructor(props) {
    super(props)
    this.modalRef = createRef()
  }

  componentDidMount() {
    const modalElement = this.modalRef.current
    if (modalElement) {
      modalElement.addEventListener('modal-closed', this.onClose)
    }
  }

  render() {
    return (
      <sh-modal
        visible={true}
        ref={this.modalRef}
        label={'Privacy Notice for CrewPlace Enterprise'}
        modal-height="calc(100% - 160px)"
        modal-width="800px"
        style={{ zIndex: 100001 }}
      >
        <div className="hm-signup-confirmation bg-white p20">
          <div className="hm-web-view bg-accent-6 web-view safari-fix">
            <header className="bg-white">
              <img alt="logo" src={logo} />
            </header>

            <br />
            <div>
              <sh-text>
                Updated: Wednesday, February 19<sup style={{ marginLeft: 0 }}>th</sup> 2025
              </sh-text>

              <br />
              <sh-text>
                Siemens Healthineers is committed to protecting and respecting the privacy of your personal data. This
                Privacy Policy explains how Siemens Healthineers processes your personal data in compliance with
                applicable data privacy laws, including federal and state laws in the United States and the EU General
                Data Protection Regulation (GDPR) when registering for or using the Siemens Healthineers CrewPlace
                Enterprise browser version platform and service.
              </sh-text>

              <br />
              <sh-text size="super-header">Controller</sh-text>
              <sh-text>
                Siemens Healthineers will process your personal data as the data controller in accordance with this
                Privacy Policy when using the CrewPlace Enterprise browser version platform and service.
              </sh-text>

              <br />
              <sh-text size="super-header">Categories of personal data processed</sh-text>
              <sh-text>
                Siemens Healthineers may process the following categories of personal data about you when using the
                CrewPlace Enterprise browser version platform and service:
                <ul style={{ marginBottom: 0 }}>
                  <li style={{ listStyleType: 'circle' }}>
                    Contact information, including your name, e-mail address, telephone number, that you provide to us
                  </li>
                  <li style={{ listStyleType: 'circle' }}>
                    Job information, including occupation, job title, work location, department, position, number of
                    years of experience
                  </li>
                </ul>
              </sh-text>

              <br />
              <sh-text size="super-header">Purposes for processing your personal data </sh-text>
              <sh-text>Siemens Healthineers processes your personal data for the following purposes:</sh-text>
              <ul style={{ marginBottom: 0 }}>
                <li>To provide the CrewPlace Enterprise browser version platform and service you request</li>
                <li>To process your individual requests</li>
                <li>To verify your identify (if you registered to a service offered via the website)</li>
                <li>To fulfil our obligations under contracts concluded between you and us</li>
                <li>To enforce our Terms of Use</li>
                <li>To assert or defend legal claims</li>
                <li>To prevent and deter fraudulent or similar acts, including attacks on our IT infrastructure</li>
              </ul>

              <br />
              <sh-text size="super-header">Legal basis for the processing</sh-text>
              <sh-text>
                The legal basis for Siemens Healthineers processing your personal data is that such processing is
                necessary for the purpose of safeguarding the legitimate business interests of Siemens Healthineers
                (Art. 6 (1) lit. f GDPR). The legitimate interest of Siemens Healthineers lies in the processing of your
                personal data for the purpose of providing our offerings and services, ensuring the technical stability
                and security of our services. Where Siemens Healthineers relies on its legitimate interests for
                processing personal data, Siemens Healthineers has determined that, after a balancing of interests, its
                legitimate interests are not overridden by your interests and rights or freedoms. More information on
                the balancing of interests can be obtained by contacting the Siemens Healthineers Data Privacy
                Organization.
              </sh-text>

              <br />
              <sh-text size="super-header">Consent for processing personal data </sh-text>
              <sh-text>
                There is a consent form displayed to you before you attempt an assessment or questionnaire in CrewPlace
                Enterprise. If you agree to process the data based on the consent form, we proceed further with the
                assessment steps.
              </sh-text>

              <br />
              <sh-text size="super-header">Cookies and other similar technologies </sh-text>
              <sh-text>
                Siemens Healthineers uses cookies and other similar technologies to operate Siemens Healthineers’
                websites and, for example, to personalize content and ads. You may find out more about how we use
                cookies in our &nbsp;
                <a
                  className="sh-link"
                  href="https://www.healthcare.siemens.com/cookie"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cookie Policy
                </a>
                .
              </sh-text>

              <br />
              <sh-text size="super-header">Links to other websites</sh-text>
              <sh-text>
                Siemens Healthineers’ websites may contain links to third party websites and applications. This Privacy
                Policy, however, only applies to Siemens Healthineers websites and does not cover how third-party
                organizations process personal data. For information on their processing of your personal data, we
                encourage you to read their data privacy policies.
              </sh-text>

              <br />
              <sh-text size="super-header">Recipients and transfer of personal data</sh-text>
              <sh-text>
                Siemens Healthineers may share your personal data with the following recipients, if and to the extent
                such transfer is necessary:
                <ul>
                  <li>
                    Siemens Healthineers’ group companies which process personal data to support in fulfilling our
                    contractual or legal obligations or internal functions such as customer services,
                  </li>
                  <li>
                    other recipients such as business partners or (IT-) service providers which process personal data as
                    part of their service provision for Siemens Healthineers (e.g., hosting or IT maintenance and
                    support services),
                  </li>
                  <li>
                    third parties in connection with complying with legal obligations or establishing, exercising, or
                    defending rights or claims or in relation to corporate transactions (e.g., for court and arbitration
                    proceedings, to law enforcement authorities and regulators, to attorneys and consultants).
                  </li>
                </ul>
                Sometimes a recipient to whom Siemens Healthineers transfers personal data is in a country in which
                applicable laws do not provide the same level of data protection as the GDPR. In such cases and unless
                permitted otherwise by applicable law, Siemens Healthineers only transfers personal data if appropriate
                and suitable safeguards for the protection of personal data are implemented, in particular, if the
                recipient entered into the&nbsp;
                <a
                  className="sh-link"
                  href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                  target="_blank"
                  rel="noreferrer"
                >
                  EU Standard Contractual Clauses for the transfer of personal data to third countries
                </a>
                &nbsp;with us or if the recipient has introduced approved&nbsp;
                <a
                  className="sh-link"
                  href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/binding-corporate-rules-bcr_en"
                  target="_blank"
                  rel="noreferrer"
                >
                  Binding Corporate Rules
                </a>
                &nbsp;in its organization.
              </sh-text>

              <br />
              <sh-text>
                Further information on the safeguards in place is available by contacting the Siemens Healthineers Data
                Privacy Organization.
              </sh-text>

              <br />
              <sh-text size="super-header">Storage duration</sh-text>
              <sh-text>
                Siemens Healthineers will store your personal data for six months after your last contact with us,
                provided that there is no contractual relationship between your employer and us. unless legal
                obligations or the establishment, exercise or defense of legal claims make a longer retention necessary.
              </sh-text>

              <br />
              <sh-text size="super-header">Your rights</sh-text>
              <sh-text>
                Under the GDPR, you have specific rights in relation to your personal data. In particular, and subject
                to the statutory requirements, you may be entitled to:
                <ul>
                  <li>
                    obtain confirmation as to whether Siemens Healthineers processes personal data about you and, where
                    that is the case, obtain access to your personal data processed by Siemens Healthineers as well as
                    other information,
                  </li>
                  <li>obtain the rectification of your inaccurate personal data processed by Siemens Healthineers,</li>
                  <li>
                    obtain from Siemens Healthineers the erasure of your personal data processed by Siemens
                    Healthineers,
                  </li>
                  <li>obtain from Siemens Healthineers restriction of processing of your personal data,</li>
                  <li>
                    obtain a copy of your personal information that you have provided to Siemens Healthineers or request
                    that your personal information be transmitted to another recipient,
                  </li>
                  <li>
                    object on grounds relating to your particular situation to the processing of your personal data by
                    Siemens Healthineers insofar as the processing of your personal data is based on legitimate
                    interests.
                  </li>
                </ul>
              </sh-text>
              <sh-text>
                If you have given Siemens Healthineers your consent to process your personal data, you have the right to
                withdraw your consent at any time with effect for the future, i.e., your withdrawal does not affect the
                lawfulness of the processing based on consent before its withdrawal.
              </sh-text>
              <br />
              <sh-text>
                To receive more information regarding these rights or to exercise any of your rights, please contact the
                Siemens Healthineers Data Privacy Organization.
              </sh-text>
              <br />
              <sh-text size="super-header">
                Data Protection Officer, Siemens Healthineers Data Privacy Organization
              </sh-text>
              <sh-text>
                The Siemens Healthineers Data Protection Officer and the Siemens Healthineers Data Privacy Organization
                provide support with any data privacy related questions, comments, concerns, or complaints or in case
                you wish to exercise any of your data privacy related rights. They be contacted at:&nbsp;
                <a class="sh-link" href="mailto:dataprivacy.func@siemens-healthineers.com">
                  dataprivacy.func@siemens-healthineers.com
                </a>
              </sh-text>

              <br />
              <sh-text>
                The Siemens Healthineers Data Privacy Organization will always use best efforts to address and settle
                any requests or complaints brought to its attention. In addition, you may also contact a supervisory
                authority with requests or complaints. The competent lead supervisory authority for Siemens Healthineers
                with respect to the GDPR is: Bayerisches Landesamt für Datenschutzaufsicht, Promenade 18, 91522 Ansbach,
                Germany,&nbsp;
                <a class="sh-link" href="https://lda.bayern.de/en/index/html" target="_blank" rel="noreferrer">
                  lda.bayern.de/en/index/html
                </a>
              </sh-text>

              <br />
              <sh-text size="super-header">Specific Rights for California Residents Under the CCPA (USA Only)</sh-text>
              <sh-text>
                <a
                  class="sh-link"
                  href="https://www.siemens-healthineers.com/en-us/siemens-website-privacy-policy/california-state-rights"
                  target="_blank"
                  rel="noreferrer"
                >
                  Specific Rights for California Residents (siemens-healthineers.com)
                </a>
              </sh-text>
            </div>

            <br />
            <br />
            <footer>
              <img alt="logo" src={logo} style={{ marginBottom: '8px' }} />
              <div className="copyright">
                © {new Date().getFullYear()} {t('copyright_text')}
              </div>
            </footer>
          </div>
        </div>
      </sh-modal>
    )
  }
}

export default PrivacyPolicy
