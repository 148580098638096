import { useState, useEffect } from 'react'
import { Map, List, fromJS } from 'immutable'
import Header from './Header'
import Item from './Item'
import { calculateTotalTopHeight } from 'utils'
import { t } from 'i18n'
import ItemAccordion from './ItemAccordion'

const resourceTypeLabels = {
  shift: t('calendar.shift_plural'),
  equipment: t('calendar.equipment_plural'),
  subspeciality: t('calendar.subspeciality_plural')
}

const calculatePopupStyle = (popupProps) => {
  const { targetElement = { offsetLeft: 0, clientWidth: 0 }, width, viewPort } = popupProps

  const viewPortTopOffset = calculateTotalTopHeight()
  const overflowThreshold = 0

  const isWindowOverflow = window.innerHeight - viewPortTopOffset - viewPort.top - viewPort.height < overflowThreshold
  const topOffset = isWindowOverflow ? -110 : 0

  const maxWidth = window.innerWidth - width - targetElement.clientWidth
  const left = targetElement.offsetLeft < maxWidth ? width : -1 * width + 1

  return { left: `${left}px`, top: `${topOffset}px` }
}

export default function ShiftsSubmenu({
  popupProps = {},
  onClickHandler,
  primaryShift,
  secondaryShifts,
  onCallShifts,
  eventVariants,
  _cellSubIndex,
  cell
}) {
  const [eventForShift, setEventForShift] = useState(Map())

  const style = calculatePopupStyle(popupProps)

  const hasAssignmentVariant = !!eventVariants.find((variant) => variant.get('id') === 'assignment')
  const hasMeetingVariant = !!eventVariants.find((variant) => variant.get('id') === 'meeting')
  const hasOnCallVariant = !!eventVariants.find((variant) => variant.get('id') === 'onCall')

  const hasMeetingEvent = hasMeetingVariant && !!primaryShift

  useEffect(() => {
    const cellStaffEvents = cell.get('staffEvents') || List()
    let newEventForShift = {}
    cellStaffEvents.forEach((e) => {
      const staffEventShiftId = e.get('shiftId')
      const staffEventId = e.get('id')
      newEventForShift[staffEventShiftId] = staffEventId
    })
    setEventForShift(fromJS(newEventForShift))
  }, [cell])

  const renderShift = (shift) => {
    const eventVariantId = 'assignment'
    const shiftId = shift.get('id')
    const eventParameters = shift.merge({ eventVariantId, shiftId })
    return (
      <Item
        key={shiftId}
        className="shift-item"
        isCheckbox={true}
        isChecked={!!eventForShift.get(shiftId)}
        title={shift.get('name')}
        onClick={onClickHandler(eventParameters, eventForShift.get(shiftId))}
      />
    )
  }

  const renderMeetingEvent = () => {
    const startTime = primaryShift.get('startTime')
    const unitId = primaryShift.get('unitId')
    const length = primaryShift.get('length')
    const eventVariantId = 'meeting'
    const eventParameters = Map({ startTime, unitId, length, eventVariantId })

    return (
      <Item key={eventVariantId} className="shift-item" title={'Event'} onClick={onClickHandler(eventParameters)} />
    )
  }

  const shifts = secondaryShifts.push(primaryShift).merge(onCallShifts)
  const shiftsByResourceTypes = shifts.groupBy((shift) => shift.get('resourceType'))
  const resourceTypes = shiftsByResourceTypes.keySeq()

  return (
    <div data-testid="shifts-submenu" className="submenu" style={style}>
      {resourceTypes
        .map((resourceType, i) => {
          const shiftsOfResourceType = shiftsByResourceTypes.get(resourceType)
          const onCallShiftsOfResourceType = shiftsOfResourceType.filter((shift) => shift.get('isOnCall'))
          const nonOnCallShifts = shiftsOfResourceType.filter((shift) => !shift.get('isOnCall'))

          const hasNonOnCallShifts = hasAssignmentVariant && nonOnCallShifts.size > 0
          const hasOnCallShifts = hasOnCallVariant && onCallShiftsOfResourceType.size > 0

          const submenuContents = []
          if (hasNonOnCallShifts) {
            submenuContents.push(nonOnCallShifts.map(renderShift))
          }
          if (hasOnCallShifts) {
            if (submenuContents.length > 0) {
              submenuContents.push(<sh-divider spacing="s" class="shifts-separator" />)
            }
            submenuContents.push(
              <Header key="on-call-shifts-header" title="On Call" iconClassName="icon-indicator-call rock-blue" />
            )
            submenuContents.push(onCallShiftsOfResourceType.map(renderShift))
          }

          const isLastResourceType = i === resourceTypes.size - 1

          return (
            <ItemAccordion key={resourceType} label={resourceTypeLabels[resourceType] || resourceType}>
              {submenuContents}
              {(!isLastResourceType || hasMeetingEvent) && <sh-divider spacing="s" />}
            </ItemAccordion>
          )
        })
        .valueSeq()}
      {hasMeetingEvent && renderMeetingEvent()}
    </div>
  )
}
