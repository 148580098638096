import Header from './Header'
import Item from './Item'
import { TimeOffDefinitionsService } from 'services'
import { calculateTotalTopHeight } from 'utils'

export default function TimeOffsSubmenu({ popupProps = {}, onClickHandler, timeOffEventVaraints }) {
  const { targetElement = { offsetLeft: 0 }, width, viewPort } = popupProps
  const viewPortTopOffset = calculateTotalTopHeight()
  const overflowThreshold = 0

  const isWindowOverflow = window.innerHeight - viewPortTopOffset - viewPort.top - viewPort.height < overflowThreshold
  const topOffset = isWindowOverflow ? -110 : 0

  const maxWidth = window.innerWidth - width - targetElement.clientWidth
  const left = targetElement.offsetLeft < maxWidth ? width : -1 * width + 1
  const style = { left: `${left}px`, top: `${topOffset}px` }

  const ptoVariant = timeOffEventVaraints.find((variant) => variant.get('id') === 'paidTimeOff')
  const roVariant = timeOffEventVaraints.find((variant) => variant.get('id') === 'requestOff')

  const hasPTOVariant = !!ptoVariant
  const hasROVariant = !!roVariant

  if (!hasPTOVariant && !hasROVariant) {
    return null
  }

  const paidTimeOffOptions = TimeOffDefinitionsService.buildPaidTimeOffOptions()
  const unavailabilityOptions = TimeOffDefinitionsService.buildUnavailabilityOptions()

  const renderTimeOffItem = (variant, option, optionIndex) => (
    <Item
      key={`${variant.get('id')}-${optionIndex}`}
      title={option}
      className="time-off-item"
      onClick={onClickHandler(variant, option)}
    />
  )

  const submenuContents = []
  if (hasPTOVariant) {
    submenuContents.push(
      <Header
        key={`${ptoVariant.get('id')}-header`}
        isDisabled={false}
        title="Paid Time Off"
        iconClassName="icon-Money-Sign"
        iconWrapperClassName="rock-blue bg-athensgray"
        onClick={onClickHandler(ptoVariant, '')}
      />,
      ...paidTimeOffOptions.map((option, optionIndex) => renderTimeOffItem(ptoVariant, option, optionIndex))
    )
  }

  if (hasROVariant) {
    if (submenuContents.length > 0) {
      submenuContents.push(<hr key={`${roVariant.get('id')}-separator`} className="visible" />)
    }
    submenuContents.push(
      <Header
        key={`${roVariant.get('id')}-header`}
        isDisabled={false}
        title="Request Off"
        iconClassName="icon-close"
        iconWrapperClassName="rock-blue bg-athensgray"
        onClick={onClickHandler(roVariant, '')}
      />,
      ...unavailabilityOptions.map((option, optionIndex) => renderTimeOffItem(roVariant, option, optionIndex))
    )
  }

  return (
    <div data-testid="calendar-time-offs-submenu" className="submenu" style={style}>
      {submenuContents}
    </div>
  )
}
