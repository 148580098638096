import { ComponentController } from 'Common/components'
import moment from 'moment-timezone'

const currentTimezone = moment.tz.guess()
const currentLocale = moment.locale()

export default class FormController extends ComponentController {
  getFormProperties() {
    const { form } = this.state
    const { isEdit } = this.props
    form.startsAt.definition.disabled = form.startsAt.definition.disableFunc(isEdit)
    return form
  }

  isAlreadyTerminated = () => {
    const { staff } = this.props
    return staff && !!staff.getIn(['staffProfile', 'endsAt'])
  }

  serializeForm = () => {
    const { form } = this.state
    const { userId, staffPosition } = this.props
    const isAlreadyTerminated = this.isAlreadyTerminated()

    let serializedForm = { userId }

    for (const key in form) {
      if (isAlreadyTerminated && key === 'endsAt') {
        continue
      }

      if (form[key].definition.type === 'date') {
        if (form[key].value) {
          serializedForm[key] = form[key].value
        }
      } else {
        serializedForm[key] = form[key].value
      }

      if (key === 'phoneNumber') {
        serializedForm[key] = this._getPhoneNumber(form[key].value)
      }
      // HMN-3405, handle '' on maximumNumberOfHoursPerWeek field, as an empty field should'n update it.
      const doesNotHaveValue = isNaN(parseInt(serializedForm[key]))
      if (key === 'maximumNumberOfHoursPerWeek' && doesNotHaveValue) {
        serializedForm.maximumNumberOfHoursPerWeek = null
      }
    }

    serializedForm.position = staffPosition

    serializedForm.locale = currentLocale
    serializedForm.timeZone = currentTimezone
    return serializedForm
  }
  getAvailabilityType = (availabilityType) => {
    if (availabilityType === 'dayTime') {
      return 'Day'
    } else if (availabilityType === 'nightTime') {
      return 'Night'
    } else {
      return 'Any'
    }
  }

  getEmploymentType = (employmentType) => {
    if (employmentType === 'fullTime') {
      return 'Full-Time'
    } else if (employmentType === 'partTime') {
      return 'Part-Time'
    } else {
      return 'Per Diem'
    }
  }

  updateForm() {
    const { staff, managerFullName, currentUnitId, staffPosition } = this.props
    const form = this._resetForm(this.state.form)

    form.phoneNumber.definition.disabled = false
    form.email.definition.disabled = false

    if (staff) {
      form.homeUnitId.value = currentUnitId
      form.position.value = staffPosition
      Object.keys(form).forEach((field) => {
        const { path } = form[field]
        if (path) {
          form[field].value = path(staff) || form[field].value
        }
      })
    }

    form.employmentType.value = this.getEmploymentType(form.employmentType.value)
    form.availabilityType.value = this.getAvailabilityType(form.availabilityType.value)

    form.managerFullName.value = form.managerFullName.value || managerFullName

    this.setState({ form })
  }

  getFieldValue = (e, field) => {
    const { form } = this.state
    let value

    switch (form[field].definition.type) {
      case 'select':
      case 'number':
      case 'date':
      case 'array':
        value = e
        break
      default:
        value = e.target ? e.target.value : ''
    }

    return value
  }

  setFieldValue = (e, field) => {
    const { timeService } = this.props
    const { form } = this.state

    form[field].value = this.getFieldValue(e, field)
    const startsAt = form.startsAt.value
    const endsAt = form.endsAt.value

    if (field === 'startsAt' && startsAt) {
      const startsAtMoment = timeService.timeMoment(form.startsAt.value)

      if (endsAt && startsAtMoment.isSameOrAfter(endsAt)) {
        form.endsAt.value = startsAtMoment.add(1, 'day').toISOString()
      }
    }

    if (field === 'endsAt' && endsAt) {
      const endsAtMoment = timeService.timeMoment(endsAt)

      if (startsAt && endsAtMoment.isSameOrBefore(startsAt)) {
        form.startsAt.value = endsAtMoment.add(-1, 'day').toISOString()
      }
    }

    delete form[field].inlineError

    this.setState({ form })
  }

  validateField = (field) => {
    const { isEdit } = this.props
    const { form } = this.state
    form[field].validate(form, { showError: true, isEdit })
    if (field === 'email') {
      form[field].value && delete form['phoneNumber'].inlineError
    }
    if (field === 'phoneNumber') {
      form[field].value && delete form['email'].inlineError
    }

    this.setState({ form })
  }

  validateForm = (showError) => {
    const { isEdit } = this.props
    const { form } = this.state
    const fields = Object.keys(form)
    const options = { isEdit, showError }

    return fields.every((field) => form[field].validate(form, options))
  }

  isFormValid = () => {
    return this.validateForm(false)
  }

  _resetForm = (form) => {
    Object.keys(form).forEach((field) => {
      if (form[field].path) {
        delete form[field].value
        delete form[field].inlineError
      }
    })
    return form
  }

  _getPhoneNumber = (str) => {
    str = str || ''
    str = str.replace(/\D/g, '')
    return str ? `+${str}` : ''
  }
}
