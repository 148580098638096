import { PureComponent } from 'react'
import ModeFull from './ModeFull'
import ModeWeek from './ModeWeek'
import ModeDay from './ModeDay'
import './Header.scss'
import classNames from 'classnames'
import { Map } from 'immutable'
import { SwitchInput } from '../../components'

const Modes = {
  full: ModeFull,
  week: ModeWeek,
  day: ModeDay
}

export default class Header extends PureComponent {
  render() {
    const {
      mode,
      activeDateRange,
      timeService,
      filters = Map(),
      updateFilter,
      showSpinner,
      calendarType,
      unit,
      isStaffView
    } = this.props
    const isMainCalendar = calendarType === 'calendar'
    const switchProps = {
      value: filters.get('showMergedShift'),
      setConfig: (value) => updateFilter((filters) => filters.set('showMergedShift', value)),
      fieldName: 'Merge Shift',
      field: 'merge',
      switchClasses: 'hx-filters'
    }
    const Mode = Modes[mode]

    const modeProps = {
      activeDateRange: activeDateRange,
      unit: unit,
      timeService: timeService
    }
    if (mode === 'day') {
      modeProps.showSpinner = showSpinner
    }

    return (
      <section className={classNames('hx-calendar-header row bg-white', { 'flex-nowrap': !isMainCalendar })}>
        <aside className="col-2">{isMainCalendar && isStaffView && <SwitchInput {...switchProps} />}</aside>
        <header className="col-10 bg-alabaster">
          <Mode {...modeProps} />
        </header>
      </section>
    )
  }
}
