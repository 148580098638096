import React, { Component } from 'react'
import { UnitResourceProps } from '../UnitResourceAdmin'
// @ts-ignore
import { CreateEntity } from '@humanics/he-react-common/lib/admin/components'
import operationalDetailItems from '../../common/operationalDetailItems.json'
interface FormType {
  [key: string]: any
}

interface CreateUnitResourceProps extends UnitResourceProps {
  createOperationId: string
}

const inputDefinitionExtraFields = {
  name: {
    label: 'Name',
    type: 'string',
    required: true,
    formItemClasses: 'offset-1 col-7 flex',
    formLabelClasses: 'flex col-5 align-right pr20'
  },
  unitId: {
    label: 'Unit',
    inputType: 'InputDropdownUnits',
    model: 'unit',
    withLabel: true,
    required: true,
    formItemClasses: 'offset-1 col-7 flex',
    formLabelClasses: 'flex col-5 align-right pr20'
  },
  defaultDetails: {
    label: 'Default Operational Setting',
    inputType: 'InputOperationalDetail',
    model: 'unitResourceDefaultDetails',
    formItemClasses: 'offset-3',
    items: operationalDetailItems
  },
  operationalDetails: {
    label: 'Custom Operational Setting',
    inputType: 'InputOperationalDetails',
    default: [],
    dependsOnFormData: true,
    items: {
      isWorking: {
        type: 'boolean',
        default: false
      },
      operationalDetail: {
        label: 'operationalDetail',
        inputType: 'InputOperationalDetail',
        items: operationalDetailItems
      }
    }
  }
}

export default class CreateUnitResource extends Component<CreateUnitResourceProps> {
  onSubmit = (e: React.FormEvent, form: FormType) => {
    const { type, store, history,createOperationId } = this.props
    const updatedForm = {
      ...form,
      unitResourceType: type
    }
    return store.create({ createOperationId, form: updatedForm }, null, { unitResourceTypes: [type] }).then(() => history.push('.'))
  }

  render() {
    const createProps = {
      ...this.props,
      inputDefinitionExtraFields
    }
    return <CreateEntity {...createProps} onSubmit={this.onSubmit} width={900} />
  }
}

